import React, {ForwardedRef, useMemo} from 'react'
import { Button, ButtonProps, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'

import { classNames } from '../../utils/classNames'

interface BaseButtonProps {
  text: string
  isLoading?: boolean
  className?: string
  faIcon?: IconProp
  ref? :ForwardedRef<any>
  iconPosition?: 'left' | 'right'
}

const BaseButton = ({ text, isLoading, className, faIcon, iconPosition = 'left', ...props }: BaseButtonProps & ButtonProps) => {
  const classes = useMemo(() => classNames('w-100 d-flex gap-2 justify-content-center align-items-center', className), [className])

  return (
      <Button className={classes} {...props}>
        {isLoading ? (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
              <span>Loading...</span>
            </>
        ) : (
            <>
              {faIcon && iconPosition === 'left' && <FontAwesomeIcon icon={faIcon}/>}
              <span>{text}</span>
              {faIcon && iconPosition === 'right' && <FontAwesomeIcon icon={faIcon}/>}
            </>
        )}
      </Button>
  )
}

export default BaseButton
