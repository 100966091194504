import React, { useMemo } from 'react'
import Obfuscate from 'react-obfuscate'
import { classNames } from '../../utils/classNames'

interface ProtectedLinkProps {
  data: string
  className?: string
}

export const ProtectedMailTo = ({ data, className }: ProtectedLinkProps) => {
  const classes = useMemo(() => classNames(className), [className])

  return <Obfuscate email={data} className={classes} />
}

export const ProtectedPhone = ({ data, className }: ProtectedLinkProps) => {
  const classes = useMemo(() => classNames(className), [className])

  return <Obfuscate tel={data} className={classes} />
}
