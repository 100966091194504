import { GetServerSideProps, GetServerSidePropsContext, NextPage } from 'next'
import React, { useCallback, useMemo, useState } from 'react'
import { Form, Container, Row, Card, Col, Alert } from 'react-bootstrap'
import { signIn } from 'next-auth/react'
import Image from 'next/image'

import BaseInput from '../components/ui/BaseInput'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import BaseButton from '../components/ui/BaseButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faInfoCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import BaseAlert from '../components/ui/BaseAlert'
import { ProtectedMailTo } from '../components/ui/ProtectedLink'

interface IFormValues {
  username: string
  password: string
}

const Login: NextPage = () => {
  const { t } = useTranslation('login')
  const router = useRouter()
  const callbackUrl = useMemo(() => {
    if (router.query?.callbackUrl && !router.query.callbackUrl.includes('/logout')) {
      return router.query.callbackUrl as string
    } else {
      return '/'
    }
  }, [router.query])

  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const { control, handleSubmit } = useForm<IFormValues>({
    defaultValues: {
      username: '',
      password: ''
    }
  })

  const onSubmit: SubmitHandler<IFormValues> = useCallback(
    async ({ username, password }) => {
      try {
        setLoading(true)

        const res = await signIn('credentials', {
          username,
          password,
          redirect: false,
          callbackUrl: callbackUrl
        })

        if (res?.error) {
          setError(res.error)
        } else {
          router.push(`${res?.url}`)
        }
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [router, callbackUrl]
  )

  return (
    <Container className="vh-100">
      <Row className="h-100 d-flex justify-content-center align-items-center">
        <Col md={9} lg={12} xl={10}>
          <Card className="shadow-lg overflow-hidden">
            <Card.Body className="p-0">
              <Row>
                <Col lg={6} className="d-none d-lg-block p-0">
                  <div className="d-flex align-items-center justify-content-center bg-linear w-100 h-100 px-4">
                    <Image src="/unifiber_logo_white.svg" alt="Unifiber logo" width={408} height={98} priority={true} />
                  </div>
                </Col>
                <Col lg={6} className="p-0">
                  <div className="p-5">
                    <BaseAlert variant="info" faIcon={faInfoCircle}>
                      {t('login-info')}
                      <ProtectedMailTo data={t('unifiber-it-email')} className="alert-link" />
                    </BaseAlert>
                    <h3 className="text-center text-primary mb-3 fw-bolder">{t('title')}</h3>
                    {error && (
                      <BaseAlert variant="danger" faIcon={faTriangleExclamation}>
                        {error}
                      </BaseAlert>
                    )}
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <BaseInput name="username" id="usernameField" control={control} label={t('form.username')} type="text" />
                      <BaseInput name="password" id="passwordField" control={control} label={t('form.password')} type="password" />
                      <BaseButton text={t('form.submit')} variant="primary" type="submit" disabled={loading} isLoading={loading} className="mt-3" />
                    </Form>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ locale }: Pick<GetServerSidePropsContext, 'locale'>) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, ['login']))
    }
  }
}

export default Login
