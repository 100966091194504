import React, { useMemo } from 'react'
import { Alert, AlertProps } from 'react-bootstrap'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { classNames } from '../../utils/classNames'

interface BaseAlertProps {
  faIcon: IconProp
  children: React.ReactNode
}

const BaseAlert = ({ children, variant, faIcon, className }: BaseAlertProps & AlertProps) => {
  const classes = useMemo(() => classNames('d-flex align-items-center gap-2', className), [className])

  return (
    <Alert variant={variant} className={classes}>
      <FontAwesomeIcon icon={faIcon} />
      <span>{children}</span>
    </Alert>
  )
}

export default BaseAlert
