import { DefaultTFuncReturn } from 'i18next'
import { Form, FormControlProps } from 'react-bootstrap'
import { ErrorOption, FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { classNames } from '../../utils/classNames'

interface BaseInputProps<TFieldValues extends FieldValues> extends UseControllerProps<TFieldValues> {
  id: string
  label?: string | DefaultTFuncReturn
  as?: 'input' | 'textarea'
  error?: ErrorOption
}

const BaseInput = <TFieldValues extends FieldValues>({
  id,
  name,
  label,
  control,
  as = 'input',
  rules,
  error,
  ...props
}: BaseInputProps<TFieldValues> & FormControlProps) => {
  const { field } = useController({ name, control, rules })

  return (
    <Form.Group className="mb-3" controlId={id}>
      {label && <Form.Label className={classNames('fw-bold', rules?.required && 'required')}>{label}</Form.Label>}
      <Form.Control {...field} as={as} isInvalid={!!error} {...props} />
      {error && <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>}
    </Form.Group>
  )
}

export default BaseInput
